<template>
  <div class="companyRegister-wrap">
    <div class="companyRegister-left">
      <img
        src="./images/companyRegister-logo.png"
        class="companyRegister-left-logo"
      />
      <img
        src="./images/companyRegister-bg.png"
        class="companyRegister-left-bg"
      />
    </div>
    <div :class="['companyRegister-right', type != 1 ? 'center' : '']">
      <div :class="['companyRegister-right-container', type == 1 ? 'top' : '']">
        <div class="companyRegister-box" v-if="type == 1">
          <div class="companyRegister-title">创建新企业</div>
          <div class="companyRegister-form">
            <el-form
              label-width="76px"
              :model="firstForm"
              ref="firstForm"
              :rules="firstRules"
              :key="1"
              inline-message
            >
              <el-form-item label="手机号码" prop="phone">
                <el-input
                  v-model="firstForm.phone"
                  placeholder="请输入你的手机号"
                ></el-input>
              </el-form-item>
              <!-- <div class="companyRegister-attention" v-if="registerFlag">
                <img src="./images/attention.png" />
                <span>手机号{{ phone }}已注册,可直接登录</span>
              </div> -->
              <el-form-item label="验证码" prop="code">
                <el-input
                  v-model="firstForm.code"
                  placeholder="请输入验证码"
                ></el-input>
                <div
                  :class="[
                    'companyRegister-getCode',
                    sendDisabled || !canSendCode ? 'send-disabled-text' : '',
                    sendTextColor ? 'send-grey-text' : '',
                  ]"
                  @click="sendCode"
                >
                  {{ sendText }}
                </div>
              </el-form-item>
            </el-form>
            <div class="companyRegister-next">
              <!-- <v-button
                v-if="registerFlag"
                class="companyRegister-next-button"
                text="立即登录"
                @click="toLogin()"
              ></v-button> -->
              <v-button
                v-if="!registerFlag"
                class="companyRegister-next-button"
                text="下一步"
                :disabled="isResing"
                @click="submitForm('firstForm')"
              ></v-button>
              <div class="companyRegister-login-now"><span @click="toLogin()">已有账号，立即登录</span></div>
            </div>
          </div>
        </div>
        <div class="companyRegister-box" v-if="type == 2">
          <div class="companyRegister-title">创建新企业</div>
          <div class="companyRegister-form">
            <el-form
              label-width="76px"
              :model="form"
              ref="form"
              :rules="rules"
              :key="2"
              inline-message
            >
              <el-form-item label="企业名称" prop="name">
                <el-input
                  v-model="form.name"
                  placeholder="请输入企业名称"
                  maxlength="36"
                ></el-input>
              </el-form-item>
              <el-form-item label="联系人" prop="userName">
                <el-input
                  v-model="form.userName"
                  placeholder="请输入联系人"
                  maxlength="15"
                ></el-input>
              </el-form-item>
              <el-form-item label="登录密码" prop="password">
                <el-input
                  type="password"
                  v-model="form.password"
                  placeholder="请设置登录密码"
                ></el-input>
              </el-form-item>
              <el-form-item label="确认密码" prop="checkpass">
                <el-input
                  type="password"
                  v-model="form.checkpass"
                  placeholder="请确认登录密码"
                ></el-input>
              </el-form-item>
              <!-- <el-form-item label="推广编码" prop="tenantChannelId">
                <el-input
                  v-model="form.tenantChannelId"
                  placeholder="选填"
                  type="number"
                ></el-input>
              </el-form-item> -->
            </el-form>
          </div>
          <div class="companyRegister-next">
            <div class="companyRegister-deal">
              <img
                :src="
                  deal
                    ? require('./images/checked.png')
                    : require('./images/check.png')
                "
                @click="deal = !deal"
              />
              <div>同意<a @click="getDeal">《邑生活智慧社区服务条款》</a></div>
            </div>
            <v-button
              class="companyRegister-next-button"
              text="下一步"
              :disabled="isResing"
              @click="submitForm('form')"
            ></v-button>
          </div>
        </div>
        <div class="companyRegister-create" v-if="type == 3">
          <img
            src="./images/create-success.png"
            class="companyRegister-create-icon"
          />
          <div class="companyRegister-create-text">企业创建成功</div>
          <v-button
            class="companyRegister-next-button"
            text="立即体验"
            @click="toExperience()"
          ></v-button>
        </div>
      </div>
    </div>

    <el-dialog
      :width="dialogWidth"
      :visible.sync="protocolDialog"
      @closed="protocolDialog = false"
    >
      <div v-html="protocol" class="deal-wrapper"></div>
    </el-dialog>
  </div>
</template>

<script>
import { mobileRegular } from "common/regular";

export default {
  components: {},
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请设置登录密码"));
      } else {
        if (this.form.checkpass !== "") {
          this.$refs.form.validateField("checkpass");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请确认登录密码"));
      } else if (value !== this.form.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    var validatePhone = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入手机号"));
      } else if (!mobileRegular.test(value)) {
        callback(new Error("请输入正确的手机号"));
      } else {
        callback();
      }
    };
    var validateCode = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入验证码"));
      } else {
        callback();
      }
    };
    return {
      phone: '',
      protocol: "",
      firstForm: {
        phone: "",
        code: "",
      },
      firstRules: {
        phone: [{ validator: validatePhone, trigger: "blur" }],
        code: [{ validator: validateCode, trigger: "blur" }],
      },
      form: {
        phone: "",
        userName: "",
        name: "",
        password: "",
        checkpass: "",
        tenantChannelId: "",
        verCode: ""
      },
      rules: {
        name: [{ required: true, message: "请输入企业名称", trigger: "blur" }],
        userName: [{ required: true, message: "请输入联系人", trigger: "blur" }],
        password: [{ validator: validatePass, trigger: "blur" }],
        checkpass: [{ validator: validatePass2, trigger: "blur" }]
      },
      sendText: "获取验证码",
      sendDisabled: false,
      sendCodeTimer: undefined,
      time: 60,
      isResing: false,
      sendTextColor: false,
      deal: false, //协议是否同意标志
      type: 1,
      registerFlag: false, //手机号是否已注册标志
      protocolDialog: false,
    };
  },
  destroyed() {
    clearInterval(this.sendCodeTimer);
  },
  created() {},
  computed: {
    canSendCode() {
      let regExp = mobileRegular;
      return regExp.test(this.firstForm.phone);
    },
    dialogWidth() {
      let dialogWidths = "980px";
      if (
        document.body.clientWidth < 1441 ||
        document.body.offsetWidth < 1441
      ) {
        dialogWidths = "880px";
      } else {
        dialogWidths = "980px";
      }
      return dialogWidths;
    },
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (formName == "firstForm") {
            this.submit()
          } else {
            if (this.deal) {
              this.submit1()
            } else {
              this.$message.error("请先阅读并同意服务条款");
            }
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    submit() {
      this.isResing = true;
      this.$axios
        .get(
          `${API_CONFIG.butlerBaseURL}manager/checkMobileNum?mobileNum=${this.firstForm.phone}`
        )
        .then((res) => {
          if (res.status === 100) {
            if (!res.data) {
              this.submitCheck()
            } else {
              this.registerFlag = true;
              this.phone = this.firstForm.phone
              this.isResing = false;
            }
          } else {
            this.$message(res.msg);
            this.isResing = false;
          }
        });
    },

    async submitCheck() {
      let postData = {
        mobileNum: this.firstForm.phone,
        verCode: this.firstForm.code,
        mobileType: "999",
      };
      const { status } = await this.$axios({
        method: "post",
        url: `${API_CONFIG.butlerBaseURL}manager/verifyCode`,
        data: this.$qs.stringify(postData),
      });
      if (status === 100) {
        this.form.phone = this.firstForm.phone;
        this.form.verCode = this.firstForm.code
        this.type = 2;
        this.isResing = false;
      } else {
        this.isResing = false;
      }
    },

    async submit1() {
      this.isResing = true;
      let postData = {
        mobileNum: this.form.phone,
        userName: this.form.userName,
        name: this.form.name,
        password: this.form.password,
        tenantChannelId: parseInt(this.form.tenantChannelId),
        verCode: this.form.verCode,
        mobileType: "999",
      };
      if(!this.form.tenantChannelId){
        delete postData.tenantChannelId
      }
      const { status } = await this.$axios({
        method: "post",
        url: `${API_CONFIG.butlerBaseURL}tenant/addTry`,
        data: postData,
      });
      if (status === 100) {
        this.type = 3;
        this.isResing = false;
      } else {
        this.isResing = false;
      }
    },

    async sendCode() {
      if (this.sendDisabled || !this.canSendCode) {
        return;
      }
      let postData = {
        type: 4,
        phone: this.firstForm.phone,
      };
      const { status } = await this.$axios({
        method: "post",
        url: `${API_CONFIG.butlerBaseURL}common/sendCode`,
        headers: {
          mobileType: 999,
        },
        data: this.$qs.stringify(postData),
      });

      if (status === 100) {
        this.sendDisabled = true;
        this.startTimer();
        this.$message.success("验证码发送成功");
      }
    },

    startTimer() {
      let _this = this;
      this.sendCodeTimer = setInterval(() => {
        if (_this.time <= 0) {
          _this.time = 60;
          _this.sendText = "获取验证码";
          _this.sendDisabled = false;
          _this.sendTextColor = false;
          clearInterval(_this.sendCodeTimer);
        } else {
          _this.sendTextColor = true;
          _this.sendText = `重发验证码(${_this.time}s)`;
          _this.time--;
        }
      }, 1000);
    },
    toExperience() {
      this.$router.replace({
        name: "welcome",
      });
    },
    toLogin() {
      this.$router.push({
        name: "login",
      });
    },

    getDeal() {
      this.$axios
        .get(
          `${API_CONFIG.butlerBaseURL}sysAppAgreement/getAgreementByMobileType?agreementType=48`
        )
        .then((res) => {
          if (res.status === 100) {
            this.protocol = res.data.agreementDoc;
            this.protocolDialog = true;
          } else {
            this.$message(res.msg);
          }
        });
    },
  },
};
</script>

<style scoped lang="scss">
.companyRegister-wrap {
  display: flex;
  background: #fff;
  height: 100%;
  .companyRegister-left {
    width: 53%;
    background: #f4faff;
    position: relative;
    display: flex;
    padding: 30px 0 0 34px;
    align-items: center;
    justify-content: center;
    .companyRegister-left-logo {
      width: 276px;
      height: 54px;
      position: absolute;
      top: 38px;
      left: 15%;
    }
    .companyRegister-left-bg {
      width: 490px;
      height: 490px;
    }
  }
  .companyRegister-right {
    width: 47%;
    display: flex;
    justify-content: center;
    &.center {
      align-items: center;
    }
    .companyRegister-right-container {
      &.top {
        top: 23%;
        position: relative;
      }

      .companyRegister-box {
        width: 444px;
        .companyRegister-title {
          text-align: left;
          font-size: 24px;
          font-weight: bold;
          color: #1b1d33;
          padding-bottom: 97px;
        }
        .companyRegister-form {
          .companyRegister-attention {
            display: flex;
            align-items: center;
            height: 36px;
            background: rgba(255, 87, 26, 0.1);
            border-radius: 2px;
            border: 1px solid rgba(255, 87, 26, 0.3);
            font-size: 14px;
            color: #bc6c50;
            margin-left: 76px;
            img {
              width: 22px;
              height: 22px;
              margin: 0 10px 0 19px;
            }
          }
          .companyRegister-getCode {
            position: absolute;
            right: 12px;
            top: 0;
            font-size: 14px;
            color: #0d96ff;
            cursor: pointer;
          }
          .send-disabled-text {
            cursor: not-allowed;
          }
          .send-grey-text {
            color: #b0b1b8;
          }
        }
        .companyRegister-next {
          padding-left: 76px;
          margin-top: 60px;
          .companyRegister-deal {
            display: flex;
            align-items: center;
            color: #1b1d33;
            font-size: 14px;
            padding-bottom: 16px;
            img {
              width: 16px;
              height: 16px;
              margin-right: 8px;
              cursor: pointer;
            }
            a {
              color: #0d96ff;
              cursor: pointer;
            }
          }
          .companyRegister-next-button {
            width: 100%;
            height: 40px;
            line-height: 40px;
            background: #0d96ff;
            border-radius: 3px;
            font-size: 16px;
            font-weight: bold;
            color: #ffffff;
            cursor: pointer;
          }
          .companyRegister-login-now{
            font-size: 14px;
            color: #0D96FF;
            padding-top: 16px;
            span{
              cursor: pointer;
            }
            span:hover{
              color: #027edc;
            }
          }
        }
      }
      .companyRegister-create {
        width: 368px;
        .companyRegister-create-icon {
          width: 269px;
          height: 145px;
        }

        .companyRegister-create-text {
          font-size: 24px;
          font-weight: bold;
          color: #1b1d33;
          padding: 24px 0 100px;
        }
        .companyRegister-next-button {
          width: 100%;
          height: 40px;
          line-height: 40px;
          background: #0d96ff;
          border-radius: 3px;
          font-size: 16px;
          font-weight: bold;
          color: #ffffff;
          cursor: pointer;
        }
      }
    }
  }
  .el-form-item {
    margin-bottom: 16px;
  }
  .deal-wrapper{
    text-align: left;
  }
}
::v-deep .el-input__inner {
  color: #1b1d33;
  padding: 0 12px;
}
::v-deep .el-form-item__label {
  color: #5e5f66;
  padding: 0 20px 0 0;
}
::v-deep .el-form-item__label:before {
  display: none;
}
::v-deep .el-form-item__error--inline {
  text-align: left;
  display: block;
  margin-left: 0;
  padding-top: 6px;
}
::v-deep .el-dialog__header {
  background-color: transparent !important;
  padding: 20px !important;
}
::v-deep .el-dialog__body {
  height: 550px;
  overflow-y: scroll;
}
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "companyRegister-wrap" },
    [
      _vm._m(0),
      _c(
        "div",
        { class: ["companyRegister-right", _vm.type != 1 ? "center" : ""] },
        [
          _c(
            "div",
            {
              class: [
                "companyRegister-right-container",
                _vm.type == 1 ? "top" : "",
              ],
            },
            [
              _vm.type == 1
                ? _c("div", { staticClass: "companyRegister-box" }, [
                    _c("div", { staticClass: "companyRegister-title" }, [
                      _vm._v("创建新企业"),
                    ]),
                    _c(
                      "div",
                      { staticClass: "companyRegister-form" },
                      [
                        _c(
                          "el-form",
                          {
                            key: 1,
                            ref: "firstForm",
                            attrs: {
                              "label-width": "76px",
                              model: _vm.firstForm,
                              rules: _vm.firstRules,
                              "inline-message": "",
                            },
                          },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "手机号码", prop: "phone" } },
                              [
                                _c("el-input", {
                                  attrs: { placeholder: "请输入你的手机号" },
                                  model: {
                                    value: _vm.firstForm.phone,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.firstForm, "phone", $$v)
                                    },
                                    expression: "firstForm.phone",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "验证码", prop: "code" } },
                              [
                                _c("el-input", {
                                  attrs: { placeholder: "请输入验证码" },
                                  model: {
                                    value: _vm.firstForm.code,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.firstForm, "code", $$v)
                                    },
                                    expression: "firstForm.code",
                                  },
                                }),
                                _c(
                                  "div",
                                  {
                                    class: [
                                      "companyRegister-getCode",
                                      _vm.sendDisabled || !_vm.canSendCode
                                        ? "send-disabled-text"
                                        : "",
                                      _vm.sendTextColor ? "send-grey-text" : "",
                                    ],
                                    on: { click: _vm.sendCode },
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.sendText) +
                                        "\n              "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "companyRegister-next" },
                          [
                            !_vm.registerFlag
                              ? _c("v-button", {
                                  staticClass: "companyRegister-next-button",
                                  attrs: {
                                    text: "下一步",
                                    disabled: _vm.isResing,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.submitForm("firstForm")
                                    },
                                  },
                                })
                              : _vm._e(),
                            _c(
                              "div",
                              { staticClass: "companyRegister-login-now" },
                              [
                                _c(
                                  "span",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.toLogin()
                                      },
                                    },
                                  },
                                  [_vm._v("已有账号，立即登录")]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm.type == 2
                ? _c("div", { staticClass: "companyRegister-box" }, [
                    _c("div", { staticClass: "companyRegister-title" }, [
                      _vm._v("创建新企业"),
                    ]),
                    _c(
                      "div",
                      { staticClass: "companyRegister-form" },
                      [
                        _c(
                          "el-form",
                          {
                            key: 2,
                            ref: "form",
                            attrs: {
                              "label-width": "76px",
                              model: _vm.form,
                              rules: _vm.rules,
                              "inline-message": "",
                            },
                          },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "企业名称", prop: "name" } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    placeholder: "请输入企业名称",
                                    maxlength: "36",
                                  },
                                  model: {
                                    value: _vm.form.name,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "name", $$v)
                                    },
                                    expression: "form.name",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "联系人", prop: "userName" } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    placeholder: "请输入联系人",
                                    maxlength: "15",
                                  },
                                  model: {
                                    value: _vm.form.userName,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "userName", $$v)
                                    },
                                    expression: "form.userName",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: { label: "登录密码", prop: "password" },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    type: "password",
                                    placeholder: "请设置登录密码",
                                  },
                                  model: {
                                    value: _vm.form.password,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "password", $$v)
                                    },
                                    expression: "form.password",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: { label: "确认密码", prop: "checkpass" },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    type: "password",
                                    placeholder: "请确认登录密码",
                                  },
                                  model: {
                                    value: _vm.form.checkpass,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "checkpass", $$v)
                                    },
                                    expression: "form.checkpass",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "companyRegister-next" },
                      [
                        _c("div", { staticClass: "companyRegister-deal" }, [
                          _c("img", {
                            attrs: {
                              src: _vm.deal
                                ? require("./images/checked.png")
                                : require("./images/check.png"),
                            },
                            on: {
                              click: function ($event) {
                                _vm.deal = !_vm.deal
                              },
                            },
                          }),
                          _c("div", [
                            _vm._v("同意"),
                            _c("a", { on: { click: _vm.getDeal } }, [
                              _vm._v("《邑生活智慧社区服务条款》"),
                            ]),
                          ]),
                        ]),
                        _c("v-button", {
                          staticClass: "companyRegister-next-button",
                          attrs: { text: "下一步", disabled: _vm.isResing },
                          on: {
                            click: function ($event) {
                              return _vm.submitForm("form")
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm.type == 3
                ? _c(
                    "div",
                    { staticClass: "companyRegister-create" },
                    [
                      _c("img", {
                        staticClass: "companyRegister-create-icon",
                        attrs: { src: require("./images/create-success.png") },
                      }),
                      _c(
                        "div",
                        { staticClass: "companyRegister-create-text" },
                        [_vm._v("企业创建成功")]
                      ),
                      _c("v-button", {
                        staticClass: "companyRegister-next-button",
                        attrs: { text: "立即体验" },
                        on: {
                          click: function ($event) {
                            return _vm.toExperience()
                          },
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: { width: _vm.dialogWidth, visible: _vm.protocolDialog },
          on: {
            "update:visible": function ($event) {
              _vm.protocolDialog = $event
            },
            closed: function ($event) {
              _vm.protocolDialog = false
            },
          },
        },
        [
          _c("div", {
            staticClass: "deal-wrapper",
            domProps: { innerHTML: _vm._s(_vm.protocol) },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "companyRegister-left" }, [
      _c("img", {
        staticClass: "companyRegister-left-logo",
        attrs: { src: require("./images/companyRegister-logo.png") },
      }),
      _c("img", {
        staticClass: "companyRegister-left-bg",
        attrs: { src: require("./images/companyRegister-bg.png") },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }